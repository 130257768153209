import { goldPaySuccessInfo } from '@/api/index';
import { mdToast } from '../../utils/tools';
export default {
  name: 'GoldPaySuccess',
  data() {
    return {
      orderInfo: {
        // 支付金额
        pay_amount: '',
        is_card: false,
        outTradeNo: '',
        subMchId: '',
        checkCode: '',
        pay_id: ''
      },
      iframeWidth: 640
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.getUrlParams();
      this.notifyIframeReady();
    },
    async getUrlParams() {
      const urlParams = new URLSearchParams(window.location.search);
      try {
        // 调用API获取订单信息
        const {
          status,
          data,
          message
        } = await goldPaySuccessInfo({
          pay_trade_no: urlParams.get('out_trade_no')
        });
        if (status == 0 && data) {
          this.orderInfo = data;
        } else {
          mdToast(message || '请求失败');
        }
      } catch (error) {
        mdToast('请求失败');
      }
    },
    // 通知iframe准备就绪
    notifyIframeReady() {
      this.$nextTick(() => {
        const customPageHeight = document.documentElement.scrollHeight;
        const currentWidth = Math.round(document.documentElement.getBoundingClientRect().width);
        // 按照文档换算规则计算高度
        const height = Math.min(960, Math.max(600, customPageHeight * (this.iframeWidth / currentWidth)));
        const message = {
          action: 'onIframeReady',
          displayStyle: 'SHOW_CUSTOM_PAGE',
          height: Math.floor(height)
        };

        // 发送消息给父页面
        parent.postMessage(JSON.stringify(message), 'https://payapp.weixin.qq.com');
      });
    },
    // 格式化日期
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}年${month}月${day}日`;
    },
    // 跳转到外部页面
    handleClickAction() {
      // 实现去拆卡的逻辑
      console.log('去拆卡');
      if (this.orderInfo.is_card) {
        // 抽卡机拆包页
        this.jumpToExternalPage('/luckycards/prize?pay_id=' + this.orderInfo.pay_id);
      } else {
        // 通用支付成功页
        this.jumpToExternalPage('/order/success?pay_id=' + this.orderInfo.pay_id);
      }
    },
    jumpToExternalPage(url) {
      const message = {
        action: 'jumpOut',
        jumpOutUrl: url
      };
      parent.postMessage(JSON.stringify(message), 'https://payapp.weixin.qq.com');
    }
  },
  // 监听路由变化
  watch: {
    '$route'() {
      this.initPage();
    }
  }
};